import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import RulesTask from 'modules/project-view/ManageFieldsModal/RulesTask';

const RulesColumn = ({ column, tasks, handleFilter, filterText, handleRemoveRule }) => {
  if (!tasks) {
    return null;
  }

  return (
    <Col md={6} xs={12} className="rule-droppable-column">
      <h5 className="fs-0">{column.title}:</h5>
      <Droppable droppableId={column.id} key={column.id}>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ borderRadius: '2px', backgroundColor: 'lightgrey', padding: 8, minHeight: 500 }}
          >
            {column.id === 'all' && (
              <Form.Control placeholder="Filter rules" className="my-2" value={filterText} type="text" onChange={handleFilter} />
            )}
            {tasks
              ?.filter(el => el !== undefined)
              .map((task, index) => (
                <RulesTask
                  key={task?.id}
                  task={task}
                  index={index}
                  handleRemoveRule={handleRemoveRule}
                  isCloseVisible={column.id === 'saved'}
                />
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Col>
  );
};

RulesColumn.propTypes = {
  column: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleRemoveRule: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired
};

export default RulesColumn;
