import { useEffect, useState } from 'react';
import { useExtractionInstructionsMutation } from 'modules/project-view/mutations/useExtractionInstructionsMutation';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ExtractionInstructions = ({ project, id, isProjectLoading }) => {
  const { mutate: updateInstruction, isLoading: isValueUpdating } = useExtractionInstructionsMutation();

  const [value, setValue] = useState(project?.post_extraction_instructionsISsmallplaintextbox ?? '');

  useEffect(() => {
    if (project?.post_extraction_instructionsISsmallplaintextbox && !isProjectLoading) {
      setValue(project?.post_extraction_instructionsISsmallplaintextbox);
    }
  }, [project?.post_extraction_instructionsISsmallplaintextbox, setValue]);

  const handleUpdate = () => {
    updateInstruction(
      { id, post_extraction_instructionsISsmallplaintextbox: value },
      {
        onError: () => setValue(project?.post_extraction_instructionsISsmallplaintextbox)
      }
    );
  };
  return (
    <Form.Group>
      <Form.Label>
        <h5 className="mb-0 mt-2">Post Extraction Instructions</h5>
      </Form.Label>
      <Form.Control
        as="textarea"
        type="text"
        rows={3}
        style={{ resize: 'vertical' }}
        onBlur={handleUpdate}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      {isValueUpdating && <p>Updating...</p>}
    </Form.Group>
  );
};

ExtractionInstructions.propTypes = {
  project: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isProjectLoading: PropTypes.bool.isRequired
};

export default ExtractionInstructions;
