import { useMutation } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';
import pagesRoutes from 'routes/pages-routes';

const register = async data => {
  const res = await axios.post(`/app/signup`, data);
  return res.data;
};
export const useRegisterMutation = () => {
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: register,
    onMutate: () => {
      Notiflix.Loading.hourglass({ svgColor: loadingSvgColor });
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: () => {
      navigate(pagesRoutes.login);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });
  return { mutate };
};
