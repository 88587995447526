import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';

const patchLockedField = async ({ id, data }) => {
  const res = await axios.patch(`/app/bb_datasheep_projects_files/${id}`, data);
  return res.data;
};

export const useLockFileMutation = projectId => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: patchLockedField,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', projectId]);
      await queryClient.invalidateQueries(['GET_PROJECT_FILE_DETAILS', variables.id]);
    }
  });

  return { mutate, isLoading };
};
