import { useState } from 'react';

export function useSorting(initialField = 'name', initialOrder = 'ASC') {
  const [sorting, setSorting] = useState([{ id: initialField, desc: initialOrder === 'ASC' }]);

  return {
    sorting,
    onSortingChange: setSorting,
    sortAscending: !sorting.length ? false : !sorting[0].desc,
    sortBy: sorting.length ? sorting[0].id : initialField
  };
}
