import { useMutation } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';

const logIn = async credentials => {
  const res = await axios.post(`/users/login`, credentials);
  return res.data;
};

export const useLoginMutation = () => {
  const { mutate } = useMutation({
    mutationFn: logIn,
    onMutate: () => Notiflix.Loading.hourglass({ svgColor: loadingSvgColor }),
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
