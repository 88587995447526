import './ProjectViewPage.css';
import { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import FilesTable from 'modules/project-view/FilesTable';
import ManageFieldsModal from 'modules/project-view/ManageFieldsModal/ManageFieldsModal';
import FieldsAddImagesModal from 'modules/project-view/FieldsAddImagesModal';
import ProjectHeader from 'modules/project-view/ProjectHeader';
import ProjectFieldsSection from 'modules/project-view/ProjectFieldsSection';
import ExtractionInstructions from 'modules/project-view/ExtractionInstructions';
import FieldsSideMenu from 'modules/project-view/FieldsSideMenu';
import { useGetProjectQuery } from 'pages/project-view-page/queries/useGetProjectQuery';
import { Loader } from 'components/common/Loader';
import { useParams } from 'react-router-dom';

const ProjectPage = () => {
  const { id } = useParams();
  const [isManageFieldsFormVisible, setManageFieldsFormVisible] = useState(false);
  const [isAddFilesModalVisible, setAddFilesModalVisible] = useState(false);
  const [field, setField] = useState(null);
  const { data: project, isLoading: isProjectLoading, isError: hasProjectError } = useGetProjectQuery(id);

  const showManageFieldsModal = useCallback(() => {
    setManageFieldsFormVisible(true);
  }, [setManageFieldsFormVisible]);

  if (isProjectLoading) {
    return <Loader />;
  }
  if (hasProjectError) {
    return <h1>Can't load the project</h1>;
  }

  return (
    <>
      <Card className="bg-light">
        <ProjectHeader project={project} id={id} />
        <Card.Body>
          <div className="mb-5 project-page-card-wrapper">
            <div className="project-page-rules-list">
              <ProjectFieldsSection showManageFieldsModal={showManageFieldsModal} setField={setField} />
              <ExtractionInstructions project={project} id={id} isProjectLoading={isProjectLoading} />
            </div>
            <FieldsSideMenu setShowFilesModal={setAddFilesModalVisible} />
          </div>
          <FilesTable />
        </Card.Body>
      </Card>

      {isManageFieldsFormVisible && (
        <ManageFieldsModal
          closeModal={() => {
            setField(null);
            setManageFieldsFormVisible(false);
          }}
          fieldId={field}
        />
      )}

      <FieldsAddImagesModal closeModal={() => setAddFilesModalVisible(false)} isVisible={isAddFilesModalVisible} />
    </>
  );
};

export default ProjectPage;
