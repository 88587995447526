import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { useUpdateFieldsMutation } from 'pages/project-view-page/mutations/useUpdateFieldsMutation';
import PropTypes from 'prop-types';

const DraggableFieldsList = ({ setClickedField, finaleFields, showForm }) => {
  const { mutate: reorderFields } = useUpdateFieldsMutation();

  const handleEditField = fieldID => {
    setClickedField(fieldID);
  };
  const onDragEnd = async result => {
    if (!result.destination) return;

    const items = Array.from(finaleFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const reorderedData = items.map((el, index) => ({ ...el, orderNUM: index }));
    reorderFields(reorderedData);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/* Please note that react strict mode will couse issues in dev and in dev only. Works fine in production */}
      <Droppable droppableId="fields">
        {provided => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            <FalconCardHeader
              light
              title={'Fields'}
              titleTag="h5"
              endEl={
                <IconButton onClick={showForm} className="me-0 mb-1" variant="falcon-primary" size="sm" icon="plus" transform="shrink-3">
                  Add
                </IconButton>
              }
              className="pt-3 pb-1 px-0 text-start"
            />
            {finaleFields.map((field, index) => (
              <Draggable key={field._id} draggableId={field._id} index={index}>
                {provided => (
                  <li
                    className="project-page-custom-list-item"
                    onClick={() => handleEditField(field._id)}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <Flex justifyContent="between" alignItems="center">
                      <div className="project-page-truncate-text">{field?.name}</div>
                      <div className="project-page-fields-wrapper">
                        <p className="mb-0 ms-2 me-2">Rules</p>
                        <SoftBadge pill bg="primary" className="me-2">
                          {field?.rules?.length}
                        </SoftBadge>
                      </div>
                    </Flex>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableFieldsList.propTypes = {
  setClickedField: PropTypes.func,
  finaleFields: PropTypes.array,
  showForm: PropTypes.func
};
export default DraggableFieldsList;
