import React, { useCallback, useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import perPageOptions from 'modules/project-view/constants/perPageOptions';

const getProjectFiles = async ({ projectISbb_datasheep_projectsID, page, search, limit }) => {
  const params = {
    projectISbb_datasheep_projectsID,
    page,
    search,
    limit
  };
  const res = await axios.get(`/app/bb_datasheep_projects_files`, { params });
  return res.data;
};

export const useGetProjectFilesQuery = id => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(perPageOptions[0].value);

  const searchData = {
    projectISbb_datasheep_projectsID: id,
    search: searchValue ?? '',
    page: pageNumber,
    limit: perPage
  };

  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: () => getProjectFiles(searchData),
    queryKey: ['GET_PROJECT_FILES', id],
    refetchOnWindowFocus: false,
    onError: error => {
      Notiflix.Notify.failure(error.message);
    }
  });

  useEffect(() => {
    refetch();
  }, [perPage, pageNumber, searchValue]);

  const handleSetPerPage = useCallback(
    value => {
      setPerPage(value);
      setPageNumber(1);
    },
    [setPerPage]
  );

  const handleSearch = useCallback(
    value => {
      setSearchValue(value);
      setPageNumber(1);
    },
    [setSearchValue, setPageNumber]
  );

  const handleChangePageNumber = useCallback(
    page => {
      setPageNumber(page);
    },
    [setPageNumber]
  );

  const rowClickHandler = id => {
    navigate(`/fileView/${id}`);
  };

  useEffect(() => {
    if (data?.data?.length > 0) {
      const allFieldNames = data.data.reduce((accumulator, currentFile) => {
        currentFile.fields?.forEach(field => {
          if (!accumulator.includes(field.field_name)) {
            accumulator.push(field.field_name);
          }
        });
        return accumulator;
      }, []);

      const fieldColumns = allFieldNames.map((fieldName, idx) => ({
        accessorKey: fieldName,
        header: fieldName,
        id: `fieldName-${idx}`,
        cell: rowData => {
          const { _id } = rowData.row.original;
          const fieldValue = rowData.row.original.fields?.find(field => field.field_name === fieldName);
          return (
            <div className="cursor-pointer" onClick={() => rowClickHandler(_id)}>
              {fieldValue ? fieldValue.valueISsmallplaintextbox : ''}
            </div>
          );
        }
      }));
      setColumns(fieldColumns);
    }
  }, [data?.data]);

  return {
    data: data?.data ?? [],
    columns,
    pageNumber,
    setPageNumber: handleChangePageNumber,
    isLoading,
    isError,
    searchValue,
    setSearchValue: handleSearch,
    total: data?.total,
    perPage,
    setPerPage: handleSetPerPage
  };
};
