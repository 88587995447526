import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { useCreateNewProjectMutation } from 'modules/dashboard/mutations/useCreateNewProjectMutation';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Notiflix from 'notiflix';

const CreateNewProjectForm = ({ hideModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { name: '' } });

  const queryClient = useQueryClient();
  const existedProjects = queryClient.getQueryData(['ALL_PROJECTS']);
  const { mutate: createNewProject } = useCreateNewProjectMutation();

  const onSubmit = ({ name }) => {
    const projectExists = existedProjects.some(project => project.name.toLowerCase() === name.toLowerCase());

    if (projectExists) {
      return Notiflix.Notify.warning(`${name} is already in projects`);
    }
    createNewProject({ name }, { onSuccess: hideModal });
  };

  return (
    <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder="Enter a name"
          isInvalid={!!errors.name}
          {...register('name', { required: 'Name is required' })}
          type="text"
        />
        {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>}
      </Form.Group>
      <Form.Group className="my-2">
        <Button className="w-100 bg-dark" type="submit">
          Create
        </Button>
      </Form.Group>
    </Form>
  );
};

CreateNewProjectForm.propTypes = {
  hideModal: PropTypes.func.isRequired
};

export default CreateNewProjectForm;
