import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import RulesColumn from 'modules/project-view/ManageFieldsModal/RulesColumn';
import { useGetRulesQuery } from 'hooks/queries/useGetRulesQuery';

const mapBackendDataToTasks = data => {
  const tasks = {};
  data
    ?.filter(el => el !== null && el !== undefined)
    .forEach(item => {
      tasks[item._id] = {
        id: item._id,
        name: item.name,
        content: item.ruleISsmallplaintextbox
      };
    });

  return tasks;
};
const initialData = ({ sortedRules, sortedIds, projectRulesIds }) => {
  return {
    tasks: mapBackendDataToTasks(sortedRules ?? []),
    columns: {
      all: {
        id: 'all',
        title: 'Available rules',
        taskIds: sortedIds ?? []
      },
      saved: {
        id: 'saved',
        title: 'Rules to save',
        taskIds: projectRulesIds ?? []
      }
    },
    columnOrder: ['all', 'saved']
  };
};

const RulesSection = ({ projectRulesIds, typeWatch, handleMove }) => {
  const { data: allRules } = useGetRulesQuery();
  const [data, setData] = useState(initialData({ sortedRules: [], sortedIds: [], projectRulesIds: [] }));
  const [filterText, setFilterText] = useState('');

  const sortedRules = useMemo(() => {
    return typeWatch === 'text' ? allRules : allRules.filter(rule => rule.type === typeWatch);
  }, [typeWatch, allRules]);

  useEffect(() => {
    if (!sortedRules) return;

    let sortedIds = sortedRules?.map(item => item._id);
    if (projectRulesIds.length > 0) {
      sortedIds = sortedIds.filter(id => !projectRulesIds.some(projectId => projectId === id));
    }

    if (filterText) {
      const lowercasedFilter = filterText.toLowerCase();
      const filteredRules = sortedRules.filter(item => item.name.toLowerCase().startsWith(lowercasedFilter));
      sortedIds = filteredRules.map(item => item._id);
    }
    if (!filterText) {
      sortedIds = sortedRules.map(item => item._id);
    }

    sortedIds = sortedIds.filter(id => !projectRulesIds.some(projId => projId === id));
    const newData = initialData({ sortedRules, sortedIds, projectRulesIds });

    setData(newData);
  }, [sortedRules, projectRulesIds, filterText]);

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = { ...start, taskIds: newTaskIds };

      const newState = { ...data, columns: { ...data.columns, [newColumn.id]: newColumn } };
      setData(newState);
      if (finish.id === 'saved') {
        handleMove(newTaskIds);
      }
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = { ...start, taskIds: startTaskIds };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };
    handleMove(newState.columns.saved.taskIds);
    setData(newState);
  };

  const handleFilter = e => {
    setFilterText(e.target.value);
  };

  const handleRemoveRule = useCallback(
    ruleId => {
      const { saved, all } = data.columns;

      const newSavedTaskIds = saved.taskIds.filter(id => id !== ruleId);
      const newAllTaskIds = [...all.taskIds, ruleId];

      const newColumns = {
        ...data.columns,
        saved: { ...saved, taskIds: newSavedTaskIds },
        all: { ...all, taskIds: newAllTaskIds }
      };

      setData({ ...data, columns: newColumns });

      handleMove(newSavedTaskIds);
    },
    [data, setData, handleMove]
  );

  return (
    <Container>
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          {data.columnOrder.map(columnId => {
            const column = data.columns[columnId];
            const tasks = column.taskIds.map(taskId => data.tasks[taskId]);
            return (
              <RulesColumn
                handleFilter={handleFilter}
                filterText={filterText}
                key={column.id}
                column={column}
                tasks={tasks}
                handleRemoveRule={handleRemoveRule}
              />
            );
          })}
        </DragDropContext>
      </Row>
    </Container>
  );
};
RulesSection.propTypes = {
  projectRulesIds: PropTypes.array,
  handleMove: PropTypes.func.isRequired,
  typeWatch: PropTypes.string.isRequired
};

export default RulesSection;
