import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import pagesRoutes from 'routes/pages-routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from './validationSchema';
import { useRegisterMutation } from 'pages/registration-page/mutations/useRegisterMutation';

const DEFAULT_STATE_FORM = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  user_type: 1,
  isAccepted: false
};

const RegistrationPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_STATE_FORM
  });
  const { mutate: handleRegister } = useRegisterMutation();

  const onSubmit = data => {
    delete data.isAccepted;
    delete data.confirmPassword;
    handleRegister(data);
  };

  return (
    <>
      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="modalLabel">Register</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600 mb-0">
            Have an account? <Link to={pagesRoutes.login}>Login</Link>
          </p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <Form.Control placeholder="First name" {...register('first_name')} type="text" isInvalid={!!errors.first_name} />
          {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name.message}</Form.Control.Feedback>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control placeholder="Last name" {...register('last_name')} type="text" isInvalid={!!errors.last_name} />
          {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control placeholder="Email address" {...register('email')} type="text" isInvalid={!!errors.email} />
          {errors.email && <Form.Control.Feedback type="invalid">{errors.email.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control placeholder="Password" {...register('password')} type="password" isInvalid={!!errors.password} />
          {errors.password && <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Confirm Password"
            {...register('confirmPassword')}
            type="password"
            isInvalid={!!errors.confirmPassword}
          />
          {errors.confirmPassword && <Form.Control.Feedback type="invalid">{errors.confirmPassword.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
            <Form.Check.Input type="checkbox" {...register('isAccepted')} isInvalid={!!errors.isAccepted} />
            <Form.Check.Label className="form-label">
              {/*todo add links*/}I accept the <Link to="#!">terms</Link> and <Link to="#!">privacy policy</Link>
            </Form.Check.Label>
          </Form.Check>
          {errors.isAccepted && <Form.Control.Feedback type="invalid">{errors.isAccepted.message}</Form.Control.Feedback>}
        </Form.Group>

        <Form.Group className="mb-4">
          <Button className="w-100" type="submit">
            Register
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default RegistrationPage;
