import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTable from 'components/common/ReactTable/ReactTable';
import { useSorting } from 'modules/project-view/FilesTable/hooks/useSorting';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import perPageOptions from 'modules/project-view/constants/perPageOptions';
import getUrlPath from 'helpers/getUrlPath';
import ReactTableSearch from 'components/common/ReactTable/ReactTableSearch';
import { useGetProjectFilesQuery } from 'modules/project-view/queries/useGetProjectFilesQuery';
import FilesBulkActions from 'modules/project-view/FilesBulkActions';
import { useLockFileMutation } from 'hooks/mutations/useLockFileMutation';

const FilesTable = () => {
  const { onSortingChange, sorting, sortAscending, sortBy } = useSorting();
  const navigate = useNavigate();
  const { id } = useParams();

  const initColumns = [
    {
      id: 'checkbox',
      header: () => {
        return (
          <label className="mb-0 ps-2">
            <input type="checkbox" checked={deletedIds.length === files?.length} onChange={() => toggleAll()} />
          </label>
        );
      },
      cell: ({ row }) => (
        <input
          type="checkbox"
          className="mx-2"
          checked={deletedIds.includes(row.original._id) || false}
          onChange={() => toggleIdsSelection(row.original._id)}
        />
      )
    },
    {
      accessorKey: 'protectedYN',
      header: 'Lock',
      id: 'protectedYN',
      cell: rowData => {
        const { protectedYN, _id } = rowData.row.original;
        return (
          <div className="p-0 d-flex align-items-center justify-content-center cursor-pointer">
            <FontAwesomeIcon
              onClick={() => padlockHandler(_id, protectedYN)}
              className="cursor-pointer"
              icon={protectedYN === '1' ? faLock : faLockOpen}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'file',
      header: 'File',
      id: 'file',
      cell: rowData => {
        const { imageISfile, _id } = rowData.row.original;
        const path = getUrlPath() + imageISfile;
        return (
          <span className="cursor-pointer" onClick={() => rowClickHandler(_id)}>
            <img src={path} alt="File image" width={150} />
          </span>
        );
      }
    },
    {
      accessorKey: 'name',
      header: 'Name',
      id: 'name',
      cell: rowData => {
        const { name, _id } = rowData.row.original;
        return (
          <div className="cursor-pointer d-flex text-start" onClick={() => rowClickHandler(_id)}>
            {name}
          </div>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      id: 'status',
      cell: rowData => {
        const { status, _id } = rowData.row.original;
        return (
          <div className="cursor-pointer d-flex" onClick={() => rowClickHandler(_id)}>
            {status}
          </div>
        );
      }
    },
    {
      accessorKey: 'dataAdded',
      header: 'Data Added',
      id: 'dataAdded',
      cell: rowData => {
        const { _dateadded, _id } = rowData.row.original;
        return (
          <span className="cursor-pointer" onClick={() => rowClickHandler(_id)}>
            {_dateadded}
          </span>
        );
      }
    }
  ];

  const [deletedIds, setDeletedIds] = useState([]);

  const {
    data: files,
    isLoading,
    columns,
    pageNumber,
    setPageNumber,
    searchValue,
    setSearchValue,
    total,
    isError,
    perPage,
    setPerPage
  } = useGetProjectFilesQuery(id);
  const { mutate: lockFile } = useLockFileMutation(id);

  const columnRows = useMemo(() => {
    return [...initColumns, ...columns];
  }, [columns, initColumns]);

  const updatedPerPage = useMemo(() => [...perPageOptions, { label: 'All', value: total }], [total, perPageOptions]);

  const toggleIdsSelection = id => {
    setDeletedIds(prev => {
      const currentIndex = prev.indexOf(id);
      const newCheckedItems = [...prev];
      currentIndex === -1 ? newCheckedItems.push(id) : newCheckedItems.splice(currentIndex, 1);
      return newCheckedItems;
    });
  };
  const toggleAll = () => {
    setDeletedIds(prev => {
      const ids = files.map(file => file._id);
      const newCheckedItems = [...prev];
      prev.length > 0 ? (newCheckedItems.length = 0) : newCheckedItems.push(...ids);
      return newCheckedItems;
    });
  };

  const resetToggle = useCallback(() => {
    setDeletedIds([]);
  }, [setDeletedIds]);

  const rowClickHandler = id => {
    navigate(`/fileView/${id}`);
  };

  const padlockHandler = (id, protection) => {
    const data = { protectedYN: protection === '1' ? '0' : '1' };
    lockFile({ id, data });
  };

  if (isError) {
    return <h2>Error occurs while loading</h2>;
  }

  return (
    <>
      <Row className="mb-3 gap-2 justify-content-between">
        <Col>
          <ReactTableSearch searchValue={searchValue} setSearchValue={setSearchValue} />
        </Col>
        <Col>
          <FilesBulkActions selectedRowIds={deletedIds} resetToggle={resetToggle} />
        </Col>
      </Row>
      <Row>
        <ReactTable
          hasPagination
          hasPerPage
          data={files}
          columns={columnRows}
          loading={isLoading}
          perPageOptions={updatedPerPage}
          onSortingChange={onSortingChange}
          sorting={sorting}
          sortAscending={sortAscending}
          sortBy={sortBy}
          perPage={perPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          isSticky
          total={total}
          setPerPage={setPerPage}
        />
      </Row>
    </>
  );
};

export default FilesTable;
