import React, { useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Notiflix from 'notiflix';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { AuthContext } from 'context/Context';
import { Controller, useForm } from 'react-hook-form';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useParams } from 'react-router-dom';
import { useCreateFieldMutation } from 'modules/project-view/mutations/useCreateFieldMutation';
import { useAddRuleToFieldMutation } from 'modules/project-view/mutations/useAddRuleToFieldMutation';
import { useAssignTypeMutation } from 'modules/project-view/mutations/useAssignTypeMutation';
import { useDeleteFieldMutation } from 'modules/project-view/mutations/useDeleteFieldMutation';
import { useUpdateFieldMutation } from 'modules/project-view/mutations/useUpdateFieldMutation';
import RulesSection from 'modules/project-view/ManageFieldsModal/RulesSection';
import { useQueryClient } from 'react-query';
import { useGetFieldQuery } from 'modules/project-view/queries/useGetFieldQuery';
import { useEditRuleToFieldMutation } from 'modules/project-view/mutations/useEditRuleToFieldMutation';
import { Loader } from 'components/common/Loader';

const DEFAULT_FORM_VALUES = { name: '', type: null, rules: [] };

const ManageFieldsModal = ({ fieldId, closeModal, isFileView }) => {
  const { id } = useParams();
  const { appSettings, user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const finaleFields = queryClient.getQueryData('PROJECT_FIELDS', id);
  const fieldTypes = appSettings?.dropdowns?.field_types || [];
  const { data: field, isLoading } = useGetFieldQuery(fieldId);
  const [newId, setNewId] = useState(fieldId ?? null);
  const [defaultFieldRulesIds, setDefaultFieldRulesIds] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
    resetField,
    setValue
  } = useForm({ defaultValues: DEFAULT_FORM_VALUES });

  const nameWatch = watch('name');
  const typeWatch = watch('type');
  const rulesWatch = watch('rules');

  const { mutate: createField, isLoading: isFieldCreating } = useCreateFieldMutation();
  const { mutate: addRules } = useAddRuleToFieldMutation();
  const { mutate: editCreateRules } = useEditRuleToFieldMutation();
  const { mutate: assignType } = useAssignTypeMutation();
  const { mutate: deleteField } = useDeleteFieldMutation(id);
  const { mutate: updateFieldName } = useUpdateFieldMutation(id);

  const cleanUp = () => {
    reset(DEFAULT_FORM_VALUES);
  };

  // delete field fully
  const handleDeleteField = () => {
    let confirmAction = confirm('Are you sure?');
    if (confirmAction) {
      deleteField(
        { id: field._id },
        {
          onSuccess: () => {
            cleanUp();
            closeModal();
          }
        }
      );
    }
  };

  //initially form set
  useEffect(() => {
    if (field) {
      const rules = field?.rules?.sort((a, b) => +a.orderNUM - +b.orderNUM).map(rule => rule.ruleISbb_datasheep_rulesID);
      const patchedField = { rules, name: field.name, type: field.type };
      setDefaultFieldRulesIds(rules);
      reset(patchedField);
    }
  }, [field, reset]);

  //watch validation and update fields
  useEffect(() => {
    if (typeWatch !== 'text' && newId) {
      resetField('rules');
    }
  }, [typeWatch]);

  const validationTypes = useMemo(() => {
    return fieldTypes.map(type => ({ value: type, label: type }));
  }, [fieldTypes]);

  //create field from the scratch
  const handleCreateField = () => {
    const name = getValues('name');
    if (!name) {
      return Notiflix.Notify.failure('Name is required');
    }

    if (field) {
      const data = {
        projectISbb_datasheep_projectsID: !isFileView ? id : field.projectISbb_datasheep_projectsID,
        userISbb_usersID: user?.userID,
        name
      };
      return updateFieldName({ id: field._id, data });
    }

    const data = {
      name,
      projectISbb_datasheep_projectsID: id,
      userISbb_usersID: user?.userID,
      orderNUM: finaleFields?.length + 1
    };

    if (newId) {
      const val = {
        _id: newId,
        projectISbb_datasheep_projectsID: !isFileView ? id : field.projectISbb_datasheep_projectsID,
        userISbb_usersID: user?.userID,
        name
      };
      return createField({ id: newId, val });
    }

    if (!name.trim().length) return;
    if (finaleFields?.length > 0 && finaleFields?.some(el => el.name === name)) {
      return Notiflix.Notify.warning(`${name} is already in fields`);
    }

    createField({ id, data }, { onSuccess: ({ new_id }) => setNewId(new_id) });
  };

  const compareArrays = (array1, array2) => {
    const uniqueArray1 = array1.filter(obj1 => !array2.some(obj2 => obj1 === obj2));
    const uniqueArray2 = array2.filter(obj2 => !array1.some(obj1 => obj2 === obj1));
    return { uniqueInArray1: uniqueArray1, uniqueInArray2: uniqueArray2 };
  };

  //compare rules array
  const handleCompare = (arr1, arr2) => {
    const { uniqueInArray2 } = compareArrays(arr1, arr2);

    let rulesToDelete = [];
    // to del fieldRule
    if (uniqueInArray2.length > 0) {
      rulesToDelete = field.rules.reduce((acc, el) => {
        return uniqueInArray2.includes(el.ruleISbb_datasheep_rulesID) ? [...acc, el._id] : acc;
      }, []);
    }
    editCreateRules({
      fieldId: field._id,
      rules: rulesWatch,
      oldRulesIds: defaultFieldRulesIds,
      fieldRules: field.rules,
      rulesToDelete
    });
    closeModal();
  };

  // save the rules
  const onSubmit = () => {
    if (!rulesWatch.length && !field) {
      cleanUp();
      closeModal();
      return;
    }
    if (field) {
      return handleCompare(rulesWatch, defaultFieldRulesIds);
    }
    addRules(
      { id: !isFileView ? newId : field.projectISbb_datasheep_projectsID, rules: rulesWatch },
      {
        onSuccess: () => {
          cleanUp();
          closeModal();
        }
      }
    );
  };

  //close button
  const handleCheckAndClose = () => {
    closeModal();
    cleanUp();
    setNewId(null);
  };

  //update type
  const handleAssignType = () => {
    const type = getValues('type');
    const data = {
      projectISbb_datasheep_projectsID: !isFileView ? id : field.projectISbb_datasheep_projectsID,
      type
    };
    assignType({ id: newId ?? field._id, data });
  };

  const handleMove = data => {
    setValue('rules', data);
  };

  return (
    <Modal size="xl" show={true} onHide={handleCheckAndClose} aria-labelledby="create-field-modal">
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{field ? field?.name : 'Create a Field'}</Modal.Title>
        <FalconCloseButton onClick={handleCheckAndClose} />
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-2">
              <Row>
                <Col md>
                  <Form.Label>Field Name</Form.Label>
                  <Form.Control
                    placeholder="Enter a name"
                    {...register('name', { required: 'Name is required', onBlur: handleCreateField })}
                    type="text"
                    isInvalid={!!errors.name}
                  />
                  {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>}
                </Col>
                <Col md>
                  <Form.Label>Validation</Form.Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={validationTypes.find(c => c.value === field.value)}
                        options={validationTypes}
                        className="react-select poppins-regular"
                        placeholder="Choose validation"
                        onChange={({ value }) => {
                          field.onChange(value);
                          handleAssignType();
                        }}
                        isDisabled={field ? !nameWatch : !newId}
                        key="validation"
                      />
                    )}
                  />
                </Col>
              </Row>
            </FormGroup>
            {isFieldCreating && <p className="text-600"> Creating a field id...</p>}
            <Row className="mb-3 mt-4">
              {typeWatch && typeWatch !== 'Choose validation' && (
                <RulesSection projectRulesIds={rulesWatch ?? []} typeWatch={typeWatch} handleMove={handleMove} />
              )}
              <Col xs={12}>
                {field ? (
                  <Form.Group className="d-flex gap-2 text-end mt-4">
                    <Button variant="outline-secondary" className="w-100" type="submit" disabled={!nameWatch}>
                      Save
                    </Button>
                    <Button variant="falcon-danger" className="w-100" type="button" onClick={handleDeleteField}>
                      Delete
                    </Button>
                  </Form.Group>
                ) : (
                  <Button className="w-100 bg-dark mt-sm-2" type="submit">
                    {newId ? 'Save' : 'Create'}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

ManageFieldsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fieldId: PropTypes.string,
  isFileView: PropTypes.bool
};

export default ManageFieldsModal;
