import { z } from 'zod';

export const schema = z
  .object({
    first_name: z.string().nonempty('First name is required'),
    last_name: z.string().nonempty('Last name is required'),
    email: z.string().email('Invalid email address').min(1, 'Email is required'),
    password: z.string().min(6, 'Password must be at least 6 characters').min(1, 'Password is required'),
    confirmPassword: z.string().min(1, 'Confirm password is required'),
    isAccepted: z.boolean().refine(val => val === true, 'You must accept the terms and conditions')
  })
  .refine(data => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword']
  });
