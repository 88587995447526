import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import DeleteModal from 'components/common/DeleteModal';
import { useDeleteRulesMutation } from 'modules/rules/mutations/useDeleteRulesMutation';
import Select from 'react-select';
import { capitalize } from 'helpers/utils';

const options = [
  { value: 'delete', label: 'Delete' }
  // { value: 'duplicate', label: 'Duplicate' } todo no api for now
];

const RulesBulkAction = ({ deletedIds, setModal, resetIds }) => {
  const [actionValue, setActionValue] = useState(null);
  const [isDelModalVisible, setDelModalVisible] = useState(false);

  const { mutate: deleteRules } = useDeleteRulesMutation();

  const isBulkVisible = useMemo(() => {
    return deletedIds.length > 0;
  }, [deletedIds]);

  const hideDelModal = () => {
    setDelModalVisible(false);
  };

  const handleDelete = () => {
    deleteRules(deletedIds, {
      onSuccess: () => {
        hideDelModal();
        resetIds();
      }
    });
  };

  const optionsHandler = () => {
    switch (actionValue) {
      case 'delete': {
        setDelModalVisible(true);
        break;
      }
      case 'duplicate': {
        // todo need the API
        console.log(deletedIds);
        break;
      }
    }
  };

  return (
    <>
      <div className="text-end">
        {isBulkVisible ? (
          <div className="d-flex gap-2">
            <Select
              value={options.find(el => el.value === actionValue)}
              options={options}
              onChange={option => setActionValue(option.value)}
              placeholder="Select action"
              isSearchable={false}
              className="w-100"
              styles={{
                menuList: styles => ({ ...styles, zIndex: 5 }),
                control: provided => ({
                  ...provided,
                  border: '1px solid #DDDDDD',
                  cursor: 'pointer',
                  textAlign: 'left'
                }),
                option: provided => ({
                  ...provided,
                  cursor: 'pointer',
                  textAlign: 'left',
                  fontWeight: 500,
                  ':hover': {
                    ...provided[':hover'],
                    opacity: 0.9
                  }
                })
              }}
            />
            <Button type="button" disabled={!actionValue} variant="falcon-default" onClick={optionsHandler}>
              {actionValue ? capitalize(actionValue) : 'Apply'}
            </Button>
          </div>
        ) : (
          <IconButton onClick={() => setModal(true)} variant="falcon-primary" size="sm" icon="plus" transform="shrink-3">
            Add Rule
          </IconButton>
        )}
      </div>
      <DeleteModal onShow={isDelModalVisible} onHide={hideDelModal} id={deletedIds} onDelete={handleDelete} />
    </>
  );
};

RulesBulkAction.propTypes = {
  deletedIds: PropTypes.array.isRequired,
  setModal: PropTypes.func.isRequired,
  resetIds: PropTypes.func.isRequired
};

export default RulesBulkAction;
