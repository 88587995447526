import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import DeleteModal from 'components/common/DeleteModal';
import { useDeleteFilesMutation } from 'modules/project-view/mutations/useDeleteFilesMutation';
import { useParams } from 'react-router-dom';
import { capitalize } from 'helpers/utils';
import Select from 'react-select';

const options = [{ value: 'delete', label: 'Delete' }];

const FilesBulkActions = ({ selectedRowIds, resetToggle }) => {
  const { id } = useParams();

  const [actionValue, setActionValue] = useState('');
  const [isDelModalVisible, setDelModalVisible] = useState(false);
  const { mutate: deleteFiles } = useDeleteFilesMutation(id);

  const delHandler = () => {
    if (!selectedRowIds.length) {
      setDelModalVisible(false);
      return;
    }
    deleteFiles(selectedRowIds, {
      onSuccess: () => {
        if (resetToggle) {
          resetToggle();
        }

        setDelModalVisible(false);
      }
    });
  };

  const handleAction = () => {
    if (actionValue === 'delete') {
      setDelModalVisible(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Select
          value={options.find(el => el.value === actionValue)}
          options={options}
          onChange={option => setActionValue(option.value)}
          placeholder="Select action"
          isSearchable={false}
          className="w-100 w-sm-50"
          styles={{
            menuList: styles => ({ ...styles, zIndex: 5 }),
            control: provided => ({
              ...provided,
              border: '1px solid #DDDDDD',
              cursor: 'pointer',
              height: '40px',
              minWidth: 180,
              textAlign: 'left',
              ':hover': {
                ...provided[':hover'],
                border: '1px solid #DDDDDD'
              }
            }),
            option: provided => ({
              ...provided,
              cursor: 'pointer',
              textAlign: 'left',
              fontWeight: 500,
              ':hover': {
                ...provided[':hover'],
                opacity: 0.9
              }
            })
          }}
        />
        <Button type="button" variant="falcon-default" size="sm" className="ms-2" onClick={handleAction} disabled={!actionValue}>
          {actionValue ? capitalize(actionValue) : 'Apply'}
        </Button>
      </div>
      <DeleteModal onShow={isDelModalVisible} onHide={() => setDelModalVisible(false)} id={selectedRowIds} onDelete={delHandler} />
    </>
  );
};

FilesBulkActions.propTypes = {
  selectedRowIds: PropTypes.array,
  resetToggle: PropTypes.func
};

export default FilesBulkActions;
