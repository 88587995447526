import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';

const textExtraction = async data => {
  const res = await axios.post(`/app/textract`, data);
  return res.data;
};

export const useTextExtractMutation = id => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: textExtraction,
    onMutate: () => Notiflix.Loading.hourglass({ svgColor: loadingSvgColor }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['GET_PROJECT_FILE_DETAILS', id]);
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
