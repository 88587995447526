import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';

const DeleteModal = ({ onShow, onHide, id, onDelete }) => {
  const handleConfirm = () => {
    onDelete(id);
    onHide();
  };

  return (
    <Modal show={onShow} onHide={onHide} contentClassName="border-0" centered>
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <Modal.Title as="h5" className="mt-3">
          Are you sure?
        </Modal.Title>
        <FalconCloseButton noOutline variant="white" onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <Flex alignItems="center" justifyContent="end" className="mt-3">
          <Button variant="outline-secondary" size="sm" className="px-2 me-3" onClick={onHide}>
            Go Back
          </Button>
          <Button variant="outline-danger" size="sm" className="px-2" onClick={handleConfirm}>
            Yes, Delete
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onShow: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onDelete: PropTypes.func.isRequired
};

export default DeleteModal;
