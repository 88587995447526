import React, { useContext, useEffect, useMemo } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';
import { useCreateRuleMutation } from 'modules/rules/mutations/useCreateRuleMutation';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useEditRuleMutation } from 'modules/rules/mutations/useEditRuleMutation';
import { useGetRuleQuery } from 'modules/rules/queries/useGetRuleQuery';
import { useDeleteRulesMutation } from 'modules/rules/mutations/useDeleteRulesMutation';
import { useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import { Loader } from 'components/common/Loader';

const DEFAULT_FORM_VALUES = { name: '', ruleISsmallplaintextbox: '', type: null };

const ManageRulesModal = ({ isVisible, onClose, ruleId, resetRuleId }) => {
  const queryClient = useQueryClient();
  const existedRules = queryClient.getQueryData(['ALL_RULES']);
  const { appSettings, user } = useContext(AuthContext);
  const fieldTypes = appSettings?.dropdowns?.field_types || [];
  const { mutate: createRule } = useCreateRuleMutation();
  const { mutate: updateRule } = useEditRuleMutation();
  const { mutate: deleteRule } = useDeleteRulesMutation();
  const { data: rule, isLoading: isRuleLoading, isError: hasRuleError } = useGetRuleQuery(ruleId);

  useEffect(() => {
    if (rule && !isRuleLoading && !hasRuleError) {
      reset(rule);
    }
  }, [rule, isRuleLoading, hasRuleError]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({ defaultValues: DEFAULT_FORM_VALUES });

  const updatedTypes = useMemo(() => {
    return fieldTypes.map(rule => ({ value: rule, label: rule }));
  }, [fieldTypes]);
  const nameWatch = watch('name');

  const cleanUp = () => {
    reset(DEFAULT_FORM_VALUES);
    resetRuleId && resetRuleId();
    onClose();
  };

  const onSubmit = data => {
    const ruleExists = existedRules.some(rule => rule.name.toLowerCase() === nameWatch.toLowerCase());
    if (ruleExists) {
      return Notiflix.Notify.warning(`${nameWatch} is already exists`);
    }
    const values = {
      ...data,
      type: data.type.value,
      userISbb_usersID: user.userID
    };
    if (ruleId) {
      values['_id'] = ruleId;
      updateRule(
        { id: ruleId, values },
        {
          onSuccess: () => {
            cleanUp();
          }
        }
      );
    } else {
      createRule(values, {
        onSuccess: () => {
          cleanUp();
        }
      });
    }
  };

  const handleDelete = () => {
    let confirmAction = confirm('Are you sure?');
    if (confirmAction) {
      deleteRule([ruleId], {
        onSuccess: () => {
          cleanUp();
        }
      });
    }
  };

  if (hasRuleError) {
    return <h2>Can't Load Rule</h2>;
  }

  return (
    <Modal size="lg" show={isVisible} onHide={cleanUp} aria-labelledby="manage-rules-modal">
      <Modal.Header>
        <Modal.Title id="manage-rules-modal">Rule</Modal.Title>
        <FalconCloseButton onClick={cleanUp} />
      </Modal.Header>
      <Modal.Body>
        {isRuleLoading ? (
          <Loader />
        ) : (
          <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-2">
              <Row className="g-2">
                <Col md>
                  <Form.Label>Field Name</Form.Label>
                  <Form.Control
                    placeholder="Enter a name"
                    isInvalid={!!errors.name}
                    {...register('name', { required: 'Name is required' })}
                    type="text"
                  />
                  {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>}
                </Col>
                <Col md>
                  <Form.Label>Validation</Form.Label>
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: 'Type is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={updatedTypes.find(c => c.value === field.value)}
                        options={updatedTypes}
                        className="react-select poppins-regular"
                        placeholder="Choose validation"
                        isDisabled={!nameWatch}
                        key="validation"
                        isSearchable={false}
                      />
                    )}
                  />
                  {errors.type && <Form.Control.Feedback type="invalid">{errors.type.message}</Form.Control.Feedback>}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="mb-3">
              <Form.Label>Rule Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="9"
                disabled={!nameWatch}
                placeholder="Enter a description"
                {...register('ruleISsmallplaintextbox', { required: 'Description is required' })}
                type="text"
                isInvalid={!!errors.ruleISsmallplaintextbox}
              />
              {errors.ruleISsmallplaintextbox && (
                <Form.Control.Feedback type="invalid">{errors.ruleISsmallplaintextbox.message}</Form.Control.Feedback>
              )}
            </FormGroup>
            {ruleId ? (
              <Form.Group className="mb-3 text-end">
                <Button variant="outline-secondary" className="w-25" type="submit" disabled={!nameWatch}>
                  Save
                </Button>
                <Button variant="falcon-danger" className="w-25 ms-3" type="button" onClick={handleDelete}>
                  Delete
                </Button>
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Button className="w-100 bg-dark" type="submit">
                  Create
                </Button>
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

ManageRulesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ruleId: PropTypes.string,
  resetRuleId: PropTypes.func
};

export default ManageRulesModal;
