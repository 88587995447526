import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';

const patchFileField = async ({ id, data }) => {
  const res = await axios.patch(`/app/bb_datasheep_projects_files_fields/${id}`, data);
  return res.data;
};

export const useEditFieldMutation = fileId => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: patchFileField,
    onMutate: async () => {
      await queryClient.cancelQueries(['GET_PROJECT_FILE_DETAILS', fileId]);
      const previousProjectFiles = queryClient.getQueryData(['GET_PROJECT_FILE_DETAILS', fileId]);

      return { previousProjectFiles };
    },
    onError: (error, id, context) => {
      queryClient.setQueryData(['GET_PROJECT_FILE_DETAILS', fileId], context?.previousProjectFiles);
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['GET_PROJECT_FILE_DETAILS', fileId]);
    }
  });

  return { mutate, isLoading };
};
