import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Notiflix from 'notiflix';

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    // todo wait for API
    if (email) {
      Notiflix.Notify.success(`An email is sent to ${email} with password reset link`);
    }
  };

  return (
    <div className="text-center">
      <h5 className="mb-0"> Forgot your password?</h5>
      <small>Enter your email and we'll send you a reset link.</small>
      <Form className="mt-4" onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Email address"
            value={email}
            name="email"
            onChange={({ target }) => setEmail(target.value)}
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Button className="w-100" type="submit" disabled={!email}>
            Send reset link
          </Button>
        </Form.Group>

        <Link className="fs--1 text-600" to="#!">
          I can't recover my account using this page
          <span className="d-inline-block ms-1"> &rarr;</span>
        </Link>
      </Form>
    </div>
  );
};

export default ForgetPasswordPage;
