import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FileViewPageInfoTable = ({ file }) => {
  return (
    <Table borderless responsive className="page-info-table">
      <thead>
        <tr>
          <th scope="col">File</th>
          <th scope="col">Data Added</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{file.name}</td>
          <td>{file._dateadded}</td>
          <td>{file.status}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default FileViewPageInfoTable;

FileViewPageInfoTable.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _dateadded: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired
};
