import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useExtractFilesMutation } from 'hooks/mutations/useExtractFilesMutation';
import { useExtractEmptyFilesMutation } from 'modules/project-view/mutations/useExtractEmptyFilesMutation';
import { useTextExtractMutation } from 'hooks/mutations/useTextExtractMutation';
import { useExtractEmptyTextMutation } from 'modules/project-view/mutations/useExtractEmptyTextMutation';
import { useExtractDataMutation } from 'modules/project-view/mutations/useExtractDataMutation';

const FieldsSideMenu = ({ setShowFilesModal }) => {
  const { id } = useParams();
  const { mutate: extractFiles } = useExtractFilesMutation(id);
  const { mutate: extractEmptyFiles } = useExtractEmptyFilesMutation(id);
  const { mutate: extractText } = useTextExtractMutation(id);
  const { mutate: extractEmptyText } = useExtractEmptyTextMutation(id);
  const { mutate: extractData } = useExtractDataMutation(id);

  const data = { projectISbb_datasheep_projectsID: id };

  const handleExport = data => {
    const a = document.createElement('a');
    a.href = data?.download;
    a.target = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const textExportHandler = () => {
    extractData(data, {
      onSuccess: data => handleExport(data)
    });
  };

  const extractHandler = () => {
    extractFiles(data);
  };

  const emptyExtractHandler = () => {
    extractEmptyFiles(data);
  };

  const textExtractHandler = () => {
    extractText(data);
  };

  const textEmptyExtractHandler = () => {
    extractEmptyText(data);
  };

  return (
    <div className="project-page-btn-list-wrapper">
      <Button type="button" variant="falcon-primary" onClick={() => setShowFilesModal(true)}>
        Add files
      </Button>

      <Dropdown>
        <Dropdown.Toggle variant="falcon-primary" className="w-100">
          Extract fields
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={extractHandler}>Extract fields</Dropdown.Item>
          <Dropdown.Item onClick={emptyExtractHandler}>Extract empty fields</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown>
        <Dropdown.Toggle variant="falcon-primary" className="w-100">
          Extract text
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={textExtractHandler}>Extract text</Dropdown.Item>
          <Dropdown.Item onClick={textEmptyExtractHandler}>Extract empty text</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Button onClick={textExportHandler} variant="falcon-primary">
        Export Data
      </Button>
    </div>
  );
};
FieldsSideMenu.propTypes = {
  setShowFilesModal: PropTypes.func.isRequired
};

export default FieldsSideMenu;
