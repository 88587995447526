import axios from 'axios';
import { useQuery } from 'react-query';
import Notiflix from 'notiflix';

const getAllProjects = async () => {
  const res = await axios.get(`/app/bb_datasheep_projects`);
  return res.data;
};

const REFETCH_TIME_IN_MILLISECONDS = 30000;
export const useGetProjectsQuery = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ALL_PROJECTS'],
    queryFn: () => getAllProjects(),
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    refetchInterval: REFETCH_TIME_IN_MILLISECONDS
  });

  const patchedData = data?.map(el => ({
    id: el?._id,
    name: el?.name,
    files: el?.stats?.total_files,
    files_extracted: el?.stats?.files_extracted,
    extracted: el.stats?.percent_extracted,
    protectedYN: el?.protectedYN
  }));

  return { data: patchedData ?? [], isLoading, isError };
};
