import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';

const RulesTask = ({ task, index, handleRemoveRule, isCloseVisible }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            userSelect: 'none',
            padding: 16,
            margin: '0 0 8px 0',
            minHeight: '50px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            borderRadius: '5px',
            ...provided.draggableProps.style
          }}
        >
          <div className="d-flex">
            <b>{task.name}</b>
            {isCloseVisible && <FalconCloseButton size="sm" className="ms-auto" onClick={() => handleRemoveRule(task.id)} />}
          </div>
          <p>{task.content}</p>
        </div>
      )}
    </Draggable>
  );
};
RulesTask.propTypes = {
  task: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleRemoveRule: PropTypes.func.isRequired,
  isCloseVisible: PropTypes.bool
};

export default RulesTask;
