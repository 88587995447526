import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import getUrlPath from 'helpers/getUrlPath';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTextExtractMutation } from 'hooks/mutations/useTextExtractMutation';
import { useSaveExtractedTextMutation } from 'modules/file-view/mutations/useSaveExtractedTextMutation';

const FileViewReExtractText = ({ file }) => {
  const { id } = useParams();
  const [text, setText] = useState('');
  const { mutate: extractText } = useTextExtractMutation(id);
  const { mutate: handleSaveText, isLoading } = useSaveExtractedTextMutation();

  useEffect(() => {
    setText(file?.extracted_textISsmallplaintextbox ?? '');
  }, [file?.extracted_textISsmallplaintextbox]);

  const TextExtractHandler = () => {
    const data = {
      fileISbb_datasheep_projects_filesID: id
    };
    extractText(data);
  };

  const handleSave = () => {
    const value = {
      id,
      data: {
        projectISbb_datasheep_projectsID: file.projectISbb_datasheep_projectsID,
        extracted_textISsmallplaintextbox: text
      }
    };
    handleSaveText(value);
  };

  const handleSetText = e => {
    const { value } = e.target;
    setText(value);
  };

  return (
    <Row>
      <Col lg={4} md={12}>
        <div className="files-page-img-wrapper mb-4">
          <Zoom>
            <img src={getUrlPath() + `${file.imageISfile}`} alt={file.name} className="mw-100" />
          </Zoom>
        </div>
      </Col>
      <Col lg="8" md="12">
        <Row className="align-items-center justify-content-between mb-3">
          <Col>
            <h5 className="m-0">Extracted Text</h5>
          </Col>
          <Col className="text-end">
            <Button onClick={TextExtractHandler} variant="falcon-primary">
              Re-extract Text
            </Button>
          </Col>
        </Row>
        <Form.Control
          as="textarea"
          type="text"
          rows={12}
          className={`resize-none ${isLoading ? 'opacity-50' : 'opacity:100'}`}
          value={text}
          onChange={handleSetText}
          onBlur={handleSave}
        />
      </Col>
    </Row>
  );
};

FileViewReExtractText.propTypes = {
  file: PropTypes.object.isRequired,
  defaultText: PropTypes.string
};

export default FileViewReExtractText;
