import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { getSize } from 'helpers/utils';
import Zoom from 'react-medium-image-zoom';
import { useParams } from 'react-router-dom';
import { useUploadFilesMutation } from './mutations/useUploadFilesMutation';
import './FieldsAddImagesForm.css';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FieldsAddImagesModal = ({ isVisible, closeModal }) => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [errorLogs, setErrorLogs] = useState([]);
  const { uploadFiles } = useUploadFilesMutation(id, files.length);

  const handleSubmit = () => {
    uploadFiles(
      { files },
      {
        onSuccess: data => {
          const errors = data.filter(result => result?.status === 'rejected' || result?.value?.error || result?.error);
          setFiles([]);
          if (errors.length > 0) {
            setErrorLogs(errors.map(err => err?.reason || err?.value?.error || err?.error));
          } else {
            handleCloseModal();
          }
        }
      }
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(prev => [
        ...prev,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  const handleCloseModal = () => {
    closeModal();
    setFiles([]);
    setErrorLogs([]);
  };

  const filesCountToDisplayText = useMemo(() => {
    return files?.length === 1 ? '1 file to upload.' : `${files?.length} files to upload.`;
  }, [files]);

  return (
    <Modal size="lg" show={isVisible} onHide={handleCloseModal} aria-labelledby="add-files-modal">
      <Modal.Header style={{ backgroundColor: errorLogs.length > 0 ? '#FFEFD0' : '' }}>
        <Modal.Title id="add-files-modal">{errorLogs.length > 0 ? 'Errors log' : 'Add File'}</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        {errorLogs.length > 0 ? (
          <ul className="m-0 list-unstyled">
            {errorLogs.map(err => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        ) : (
          <div className="mt-2">
            <div className="mb-3">
              <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                <input name="uploadedFiles" {...getInputProps()} />
                <Flex justifyContent="center" alignItems="center">
                  <FontAwesomeIcon className="me-2" icon={faCloudUploadAlt} />
                  <p className="fs-0 mb-0 text-700">Drop your images here</p>
                </Flex>
              </div>
            </div>
            {files.length > 0 && (
              <div className="files-info">
                <p className="text-center flex-grow-1 py-1 m-0 fs-0">{filesCountToDisplayText}</p>
                <Button variant="dark" className="files-info--upload" onClick={handleSubmit}>
                  Upload files
                </Button>
              </div>
            )}
            <>
              {files.map(file => (
                <Flex alignItems="center" className="py-3 border-bottom btn-reveal-trigger" key={file.path}>
                  <Zoom>
                    <img className="rounded" width={40} height={40} src={file.preview} alt={file.path} />
                  </Zoom>
                  <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                    <div>
                      <h6 style={{ width: '200px', overflow: 'hidden' }}>{file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>
                  <IconButton className="ms-2 mb-0" variant="light" size="sm" icon="trash" onClick={() => handleRemove(file.path)} />
                </Flex>
              ))}
            </>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

FieldsAddImagesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default FieldsAddImagesModal;
