import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';
import { loadingSvgColor } from 'helpers/utils';

const deleteProjectFiles = async arrIds => {
  const request = arrIds.map(id => axios.delete(`/app/bb_datasheep_projects_files/${id}`));
  await Promise.all(request);
};
export const useDeleteFilesMutation = projectId => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: deleteProjectFiles,
    onMutate: () => {
      Notiflix.Loading.hourglass({ svgColor: loadingSvgColor });
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['GET_PROJECT_FILES', projectId]);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
