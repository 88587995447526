import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import { loadingSvgColor, loadingTextColor } from 'helpers/utils';
import axios from 'axios';

const uploadImagesOfProject = async ({ files, id }) => {
  let uploadedFilesCount = 0;
  let results = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const reader = new FileReader();

    await new Promise((resolve, reject) => {
      reader.onerror = () => reject('Error reading file.');

      reader.onload = () => {
        const stringFiles = { name: file.name, size: file.size, type: file.type, data: reader.result };
        const imgData = {
          projectISbb_datasheep_projectsID: id,
          name: stringFiles.name,
          imageISfile: stringFiles
        };

        axios
          .post(`/app/bb_datasheep_projects_files`, imgData, {
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              Notiflix.Loading.change(`Current ${percentCompleted}%, uploaded ${uploadedFilesCount}/${files.length} files.`);
            }
          })
          .then(response => {
            uploadedFilesCount++;
            resolve(response.data);
            results.push(response.data);
            Notiflix.Loading.change(`Uploaded ${uploadedFilesCount} of ${files.length} files.`);
          })
          .catch(error => reject(error));
      };

      reader.readAsDataURL(file);
    });
  }
  return results;
};

export const useUploadFilesMutation = id => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ files, id }) => uploadImagesOfProject({ files, id }),
    onMutate: () => {
      Notiflix.Loading.hourglass('Starting file upload...', {
        svgColor: loadingSvgColor,
        messageFontSize: '18px',
        messageColor: loadingTextColor
      });
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async results => {
      const errors = results?.filter(result => result?.status === 'rejected' || result?.value?.error || result?.error);
      if (errors.length === 0) {
        Notiflix.Notify.success('All files uploaded successfully!');
      }
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  const uploadFiles = ({ files }, options = {}) => {
    mutate(
      { files, id },
      {
        ...options,
        onSuccess: (data, variables, context) => {
          if (options.onSuccess) {
            options.onSuccess(data, variables, context);
          }
        }
      }
    );
  };

  return { uploadFiles };
};
