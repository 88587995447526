import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import axios from 'axios';
import { loadingSvgColor } from 'helpers/utils';

const emptyTextExtraction = async data => {
  const res = await axios.post(`/app/textract?empty_only=1`, data);
  return res.data;
};

export const useExtractEmptyTextMutation = id => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: emptyTextExtraction,
    onMutate: () => Notiflix.Loading.hourglass({ svgColor: loadingSvgColor }),
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  return { mutate };
};
