import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Notiflix from 'notiflix';

const patchProject = async info => {
  const { id, data } = info;
  const res = await axios.patch(`/app/bb_datasheep_projects/${id}`, data);
  return res.data;
};

export const usePadlockClickMutation = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: patchProject,
    onMutate: async newData => {
      const { id, data } = newData;
      await queryClient.cancelQueries(['ALL_PROJECTS']);
      const previousProject = queryClient.getQueryData(['ALL_PROJECTS']);
      queryClient.setQueryData(['ALL_PROJECTS'], oldProjects =>
        oldProjects.map(project => (project._id === id ? { ...project, ...data } : project))
      );
      return { previousProject };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(['ALL_PROJECTS'], context.previousProjects);
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['ALL_PROJECTS']);
    }
  });

  return { mutate };
};
